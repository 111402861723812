import React from "react"
import DirectorsGrid from "../components/DirectorsGrid"
import DoctorsTable from "../components/DoctorsTable"
import EmergencyMedicalCareBanner from "../components/EmergencyMedicalCareBanner"
import ManagementStaffsGrid from "../components/ManagementStaffGrid"
import Seo from "../components/Seo"
import SuperspecialityDoctorsTable from "../components/SuperspecialityDoctorsTable"
import withLayout from "../layout/withLayout"
const DoctorsPage = withLayout(({ siteData }) => {
  return (
    <>
      <Seo title="Nemcare Team" description="Doctor, Nurse and Health Workers Team of Nemcare Hospital" siteMetadata={siteData} />
      <div className="page-title-area page-title-bg2">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2>Nemcare Team</h2>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>Team</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SuperspecialityDoctorsTable />

      <section className="contact-area" style={{ marginTop: -50 }}>
        <div className="container">
          <div className="contact-inner">
            <div className="row">
              <div className={"col-sm-12"}>
                <div className={"services-details-desc"}>
                  <span className="sub-title">Doctors</span>
                  <h3>List of Consultants and Speciality</h3>
                  <DoctorsTable />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <DirectorsGrid />

      <EmergencyMedicalCareBanner siteData={siteData} />

      <ManagementStaffsGrid />
    </>
  )
})

export default DoctorsPage
