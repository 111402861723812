import React from "react"
import DoctorsData from "../../contents/doctors.yaml"
const DoctorsTable = () => {
  let doctors = DoctorsData.sort(function (a, b) {
    if (a.department < b.department) {
      return -1
    }
    if (a.department > b.department) {
      return 1
    }
    return 0
  })
  return (
    <table className={"description_table mt-4"}>
      <thead>
        <tr>
          <td>Department</td>
          <td>Consultant Name</td>
        </tr>
      </thead>
      <tbody>
        {doctors.map((d, i) => (
          <tr key={i}>
            <td className={"key-field"}>{d.department}</td>
            <td>
              <strong>{d.name}</strong>{" "}
              {d.designation ? `, ${d.designation}` : ``}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default DoctorsTable
