import React from "react"
const SuperspecialityDoctorsTable = ({ shadow = true }) => {
  return (
    <section className="contact-area">
      <div className="container">
        <div
          className="contact-inner"
          style={{ boxShadow: shadow ? true : "none" }}
        >
          <div className="row">
            <div className={"col-sm-12"}>
              <div
                className={"services-details-desc"}
                style={{ boxShadow: shadow ? true : "none" }}
              >
                <h3>Our Superspeciality Services on Saturday and Sunday</h3>
                <table className={"description_table mt-4"}>
                  <tbody>
                    <tr>
                      <td className={"bullet_cell"}>
                        <i className="fas fa-check"></i>{" "}
                      </td>
                      <td>
                        <strong>DR. J. P. MORANG</strong>, MS, MCh (Urologist)
                      </td>
                      <td>
                        OPD - 1st Saturday &amp; 3rd Sunday of Every Month
                      </td>
                    </tr>

                    <tr>
                      <td className={"bullet_cell"}>
                        <i className="fas fa-check"></i>{" "}
                      </td>
                      <td>
                        <strong>DR. SAMIRAN DAS</strong>, MD, DM (Endocrinology)
                      </td>
                      <td>OPD - 3rd Saturday of Every Month</td>
                    </tr>

                    <tr>
                      <td className={"bullet_cell"}>
                        <i className="fas fa-check"></i>{" "}
                      </td>
                      <td>
                        <strong>DR. ANKIT PATAWARI</strong>, MD, DM
                        (Rheumatology)
                      </td>
                      <td>OPD - 2nd Saturday of Every Month</td>
                    </tr>

                    <tr>
                      <td className={"bullet_cell"}>
                        <i className="fas fa-check"></i>{" "}
                      </td>
                      <td>
                        <strong>DR. SMITAKHI MEDHI</strong>, MD, DM
                        (Pulmonology)
                      </td>
                      <td>OPD - 3rd Sunday of Every Month</td>
                    </tr>

                    <tr>
                      <td className={"bullet_cell"}>
                        <i className="fas fa-check"></i>{" "}
                      </td>
                      <td>
                        <strong>DR. ANITA BARUAH</strong>, MD, DM (Dermatology)
                      </td>
                      <td>OPD - 3rd Sunday of Every Month</td>
                    </tr>

                    <tr>
                      <td className={"bullet_cell"}>
                        <i className="fas fa-check"></i>{" "}
                      </td>
                      <td>
                        <strong>DR. MUKTIKAM CHOUDHURY</strong>, MD
                        (Gynacological Oncosurgery)
                      </td>
                      <td>OPD - 1st Sunday of Every Month</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SuperspecialityDoctorsTable
