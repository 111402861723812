import React from "react"
import DirectorsData from "../../contents/directors.yaml"
const DirectorsGrid = () => {
  return (
    <section className="doctor-area pb-70" style={{ paddingTop: 20 }}>
      <div className="container">
        <div className="row">
          <div className={"col-12"} style={{ marginBottom: 50 }}>
            <span className="sub-title">Nemcare Administration</span>
            <h2>Board of Directors</h2>
          </div>
          {DirectorsData.contents.map((d, i) => (
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="single-doctor-box">
                <div className="image">
                  <img src={d.avatar} alt={d.name} />
                </div>
                <div className="content">
                  <h3>{d.name}</h3>
                  <span>{d.designation}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default DirectorsGrid
